import React, { FC, useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CustomSpinner } from '../../shared/Spinner';
import { AuthContext } from '../../context/auth';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styles from './PaymentSuccess.module.scss';
import { usePaymentFetchMutation } from './mutation';
import { PaymentMutationModel } from './mutation/types';
import { CustomLink } from 'src/components/CustomLink';
import { useTranslation } from 'react-i18next';

const PaymentSuccess: FC = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { loadUser } = authContext;
  const location = useLocation();
  const params = useParams<{ gateway: string }>();
  const { gateway } = params;
  const [timeleft, setTimeLeft] = useState<number>(8);
  const { mutate } = usePaymentFetchMutation();
  const [price] = useState(localStorage.getItem('blue_price'));
  const [redirect_home] = useState(localStorage.getItem('redirect_home'));
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const x_order_id = localStorage.getItem('blue_x_order_id');
  const payment_body_data = localStorage.getItem('blue_payment_body_data');

  useEffect(() => {
    const timer = setTimeout(() => {
      if (success) {
        setTimeLeft((old) => old - 1);
      }
    }, 1000);
    if (timeleft === 0) {
      const urlParams = new URLSearchParams(location.search);
      const successId = urlParams.get('successId');
      if (successId && !isNaN(Number(successId))) {
        navigate(`/${language}/movie/${successId}`);
      } else {
        navigate(`/${language}/movies?page=1`);
      }
      localStorage.removeItem('redirect_home');
    }
    return () => clearTimeout(timer);
  }, [timeleft, success, navigate, language, location.search]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('blue_price');
    };
  }, []);

  useEffect(() => {
    if (gateway === 'success' || gateway === 'error') {
      const message = {
        success: true,
        error: false,
      };
      setLoading(false);
      setSuccess(message[gateway]);
      return;
    }

    let paymentData = parseParams(location.search, gateway);
    if(!x_order_id || x_order_id==''){
      setLoading(false);
      setSuccess(false);
      localStorage.removeItem('blue_payment_body_data');
      throw new Error("Zahlung fehlgeschlagen!");
    }
    let body: PaymentMutationModel = {
      gateway: gateway as string,
      x_order_id: x_order_id ?? '',
    };

    if (gateway === 'Epoch') {
      body = paymentData;
    }

    if (gateway === 'Segpay') {
      body.success = paymentData.success;
    }

    if (gateway === 'MicroPayment' || gateway === 'CommPay') {
      if (paymentData.name === 'sofort') {
        if (paymentData.status === 'success') {
          setLoading(false);
          setSuccess(true);
        } else {
          setLoading(false);
          setSuccess(false);
        }
        return;
      } else {
        paymentData = payment_body_data ? JSON.parse(payment_body_data) : '';
        body.iban = paymentData.iban;
        body.first_name = paymentData.name;
        body.last_name = paymentData.lastName;
        body.bank = paymentData.bank;
      }
    }

    mutate(body, {
      onSuccess() {
        setLoading(false);
        setSuccess(true);
        localStorage.removeItem('blue_payment_body_data');
        if (localStorage.blue_token) {
          loadUser();
        }
      },
      onError(error: any) {
        setLoading(false);
        setSuccess(false);
        localStorage.removeItem('blue_payment_body_data');
        throw new Error(error?.body.message);
      },
    });

    return () => {
      localStorage.removeItem('blue_iban');
      localStorage.removeItem('blue_x_order_id');
    };
  }, [gateway, location.search, mutate, x_order_id, payment_body_data, loadUser, navigate, language]);

  const handleButtonClick = () => {
    navigate('/');
    localStorage.removeItem('redirect_home');
  };

  const parseParams = (querystring: string, gateway = 'Epoch') => {
    const params = new URLSearchParams(querystring);

    const obj: {
      gateway: string;
      name?: string;
      status?: string;
      success?: string;
      iban?: string;
      lastName?: string;
      bank?: string;
    } = {
      gateway: gateway,
    };

    for (const key of params.keys()) {
      if (params.getAll(key)?.length > 1) {
        obj[key] = params.getAll(key);
      } else {
        obj[key] = params.get(key);
      }
    }

    return obj;
  };

  const priceComma = Number(price).toFixed(2)?.toString().replace('.', ',');

  if (loading) {
    return <CustomSpinner />;
  }

  return (
    <Container>
      <Row>
        <Col className="pb-5">
          {success && (
            <React.Fragment>
              <div style={{ textAlign: 'center' }}>
                <h2>ZAHLUNG ERFOLGREICH </h2>
                <p>Sie haben {priceComma} € auf Ihr Konto eingezahlt.</p>

                <p>Der Betrag wird unter dem neutralen Firmennamen "tmc Media Group AG" in Rechnung gestellt.</p>
              </div>

              <p>
                Mit der Geltung der AGB haben Sie sich einverstanden erklärt. Die Widerrufsbelehrung haben Sie zur
                Kenntnis genommen. Sie haben ausdrücklich zugestimmt, dass wir vor Ablauf der Widerrufsfrist mit der
                Ausführung des Vertrages beginnen. Ihnen ist bekannt, dass Sie durch diese Zustimmung mit Beginn der
                Ausführung des Vertrages Ihr Widerrufsrecht verloren haben.
              </p>
              <p>Fragen? Dann hilft Ihnen unser Kundenservice.</p>
              <div style={{ textAlign: 'center' }}>
                <button onClick={handleButtonClick} className={styles.button}>
                  Weiter zu den filmen {timeleft}
                </button>
              </div>
            </React.Fragment>
          )}

          {success !== null && !success && (
            <div style={{ textAlign: 'center' }}>
              <h2>DIE ZAHLUNG IST FEHLGESCHLAGEN. </h2>
              <p>
                Bitte wenden Sie sich an unseren <CustomLink to="">Kundenservice</CustomLink> oder{' '}
                <CustomLink to="">starten die Einzahlung erneut.</CustomLink>
              </p>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentSuccess;
